import http from "./http-common";
import { AppConfig } from "../configs/app.config";

class ApiService {
  options = {
    headers: {
      Authorization: ''
    }
  }

  profile: any = null;

  constructor() {
    if (localStorage.getItem('auth')) {
      const auth = JSON.parse(localStorage.getItem('auth') || '');;
      this.options.headers.Authorization = `Bearer ${auth?.idToken}`;
    }

    if (localStorage.getItem('profile')) {
      this.profile = JSON.parse(localStorage.getItem("profile") || "")
    }
  }
  
  login() {
    return http.get(`${AppConfig.apiBaseURL}/auth/admin/login`);
  }

  search(request: SearchRequest) {
    return http.post(`${AppConfig.apiBaseURL}/v2/search`, request);
  }

  acquireToken(code: string) {
    const request = {
      code,
    };
    return http.post(
      `${AppConfig.apiBaseURL}/auth/authorization-code/callback/v2`,
      request
    );
  }

  updateMailJetContacts() {
    return http.get(`${AppConfig.apiBaseURL}/v2/mailjet/contact/managemanycontacts`);
  }

  changePassword(request: any) {
    return http.post(`${AppConfig.apiBaseURL}/auth/change-password`, request);
  }

  getDashboard() {
    return http.get(`${AppConfig.apiBaseURL}/dashboard`);
  }

  getActivePractices() {
    return http.get(`${AppConfig.apiBaseURL}/dashboard/customers`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ApiService();
