import './style.scss';
import React, { useEffect, useState } from 'react';
import ApiService from '@services/api.service';
import * as am4core from "@amcharts/amcharts4/core";
import { map } from 'lodash';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'ID',
        width: 80,
        dataIndex: 'id',
    },
    {
        title: 'Name',
        width: 250,
        render: (row) => {
            return <div>
                <p style={{ margin: 0 }}>{`${row?.name}`}</p>
                <p style={{ margin: 0, fontSize: '13px', wordBreak: 'break-word' }}>{row?.profileEmail}</p>
            </div>
        }
    },
    {
        title: 'Essential',
        dataIndex: 'essential',
        render: (value) => {
            return value ? value : '-'  
        }
    },
    {
        title: 'Professional',
        dataIndex: 'professional',
        render: (value) => {
            return value ? value : '-'  
        }
    },
    {
        title: 'Premium',
        dataIndex: 'premium',
        render: (value) => {
            return value ? value : '-'  
        }
    },
    {
        title: 'Leaders',
        dataIndex: 'complimentary',
        render: (value) => {
            return value ? value : '-'  
        }
    },
    {
        title: 'Legacy',
        dataIndex: 'legacy',
        render: (value) => {
            return value ? value : '-'  
        }
    },
];

function MedicalPractices() {
    const [searchResults, setSearchResults] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false); 
    const [paginationConfig, setPaginationConfig] = useState<any>();

    useEffect(() => {
        getActivePractices('');
    }, []);

    function getActivePractices(searchString: string) {
        setIsLoading(true);
        ApiService.getActivePractices().then((response) => {
            if (response?.data) {
                const customers = response?.data?.data?.practices;
                
                const processedData = map(customers, ((cus) => {
                    const leaderCount = cus?.medicalPractice?.learners?.filter((profile: any) => {
                        return profile.roleId === 1
                    });

                    const learnerCount = cus?.medicalPractice?.learners.filter((profile: any) => {
                        return profile.roleId === 2
                    });

                    let essential = 0;
                    let professional = 0;
                    let premium = 0;
                    let complimentary = 0;
                    let legacy = 0;

                    // eslint-disable-next-line array-callback-return
                    cus?.subscriptionLicense?.map((profile: any) => {
                        if (profile?.planId === 1) {
                            essential = profile?.seats;
                        }
                        if (profile?.planId === 2) {
                            professional = profile?.seats;
                        }
                        if (profile?.planId === 3) {
                            premium = profile?.seats;
                        }
                        if (profile?.planId === 4) {
                            complimentary = profile?.seats;
                        }
                        if (profile?.planId >= 5) {
                            legacy = profile?.seats;
                        }
                    });

                    return {
                        id: cus?.medicalPractice?.id,
                        name: cus?.medicalPractice?.practiceName,
                        email: cus?.medicalPractice?.email,
                        currentPeriodStart: cus?.currentPeriodStart,
                        currentPeriodEnd: cus?.currentPeriodEnd,
                        amount: cus?.amount,
                        leaders: leaderCount?.length,
                        learners: learnerCount?.length,
                        essential: essential >= 0 ? essential : 0,
                        professional: professional >= 0 ? professional : 0,
                        premium: premium >= 0 ? premium : 0,
                        complimentary: complimentary >= 0 ? complimentary : 0,
                        legacy: legacy >= 0 ? legacy : 0,
                    }
                }));

                setSearchResults(processedData);
            }
            setIsLoading(false);
        });
    }

    const selectedOptionChange = (selectedOption: any) => {
        setPaginationConfig(selectedOption);
    };

    return (
        <div>
             <div style={{ marginTop: 40 }}>
                 <Table bordered loading={isLoading} columns={columns} dataSource={searchResults} pagination={{ pageSize: paginationConfig?.pageSize || 50 }} scroll={{ y: 'calc(100vh - 400px)', x: 100 }} onChange={selectedOption => selectedOptionChange(selectedOption)} />
            </div>                       
        </div>
    );
}

export default MedicalPractices;