import "./style.scss";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Layout, MenuProps, theme } from 'antd';
import { AppConfig } from "@configs";

const { Header } = Layout;

const items: MenuProps['items'] = [
  {
    key: '1',
    label: 'Logout',
    onClick: () => {
      localStorage.clear();
      window.open(AppConfig.logoutRedirectUrl, "_self");
    }
  },
];

function MainHeader() {
  const { token: { colorBgContainer } } = theme.useToken();

  return (
    <Header style={{ padding: '0 40px',  background: colorBgContainer, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div></div>
      <Dropdown menu={{ items }}>
        <Avatar size={45} icon={<UserOutlined rev={undefined} />} />
      </Dropdown>
    </Header>
  );
}

export default MainHeader;