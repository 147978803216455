import './style.scss';
import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu, theme } from 'antd';
import AzureSearch from '../../pages/Search/Search';
import MainHeader from '../../layout/common/Header/MainHeader';
import apiService from '@services/api.service';
import { MailOutlined, SearchOutlined, SyncOutlined, UnlockOutlined } from '@ant-design/icons';
import { toast } from "react-toastify";
import Sider from 'antd/es/layout/Sider';
import ChangePassword from '../../components/ChangePassword';
import Dashboard from '../../pages/Dashboard/Dashboard';
import MedicalPractices from '../../pages/MedicalPractices/MedicalPractices';

const { Content } = Layout;

function MainLayout() {
  const { token: { colorBgContainer } } = theme.useToken();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tabs, setTabs] = useState<string>('1');
  const [isUpdateContactsLoading, setIsUpdateContactsLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const navigateToLogin = () => {
    apiService.login().then((resp) => {
      if (resp?.data?.url) {
        window.open(resp?.data?.url, "_self");
      }
    }).catch((error) => {
      console.error('error: ', error);
    })
  }

  const updateMailJetContacts = () => {
    setIsUpdateContactsLoading(true);

    apiService.updateMailJetContacts().then((res) => {
      setIsUpdateContactsLoading(false);
      toast.success("Contacts have been synced!", {
        position: "top-right",
      });
    }).catch((error) => {
      console.log('error: ', error);
      setIsUpdateContactsLoading(false);
      toast.error("Something went wrong!", {
        position: "top-right",
      });
    });
  }

  useEffect(() => {
    setTimeout(() => {
      const authToken = localStorage.getItem('auth');
      if (authToken) {
        setIsLoggedIn(true);
        setIsLoading(false);
      } else {
        navigateToLogin();
      }
    }, 1000);
  }, []);

  const items = [
    {
      label: "Dashboard",
      key: 1,
      icon: <UnlockOutlined rev={undefined} />
    },
    {
      label: "Search",
      key: 2,
      icon: <SearchOutlined rev={undefined} />
    },
    {
      label: "Mailjet",
      key: 3,
      icon: <MailOutlined rev={undefined} />
    },
    {
      label: "Change Password",
      key: 4,
      icon: <UnlockOutlined rev={undefined} />
    },
    {
      label: "Practices",
      key: 5,
      icon: <UnlockOutlined rev={undefined} />
    },
  ]

  return (
    <Layout>
      {
        !isLoading && <>
          <Sider collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <img className="demo-logo-vertical" style={{ height: '50px' }} src="https://images.squarespace-cdn.com/content/v1/642793d9ca3c4a75005acc38/be04dceb-1f22-4c2b-a2a6-d4befb173746/Header-Logo.png?format=1500w" alt="" />
            <Menu onSelect={(e) => { setTabs(e.key) }} theme="dark" defaultSelectedKeys={[tabs]} mode="inline" items={items} />
          </Sider>

          <Layout>
            <MainHeader></MainHeader>
            <Content
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 'calc(100vh - 200px)',
                background: colorBgContainer,
              }}
            >
              <div className="main-container">
                {tabs === '1' && <div className="section-header">
                  <Dashboard />
                </div>
                }
                {tabs === '2' && <AzureSearch></AzureSearch>}
                {tabs === '3' && <div className="section-header">
                  <h2>Mailjet</h2>
                  <Button loading={isUpdateContactsLoading} icon={<SyncOutlined rev={undefined} />} onClick={() => { updateMailJetContacts() }} type="primary" shape="round" size={'large'}>
                    Sync Contacts
                  </Button>
                </div>
                }
                {tabs === '4' && <div className="section-header">
                  <h2>Change Password</h2>
                  <ChangePassword />
                </div>
                }
                {tabs === '5' && <div className="section-header">
                  <h2>Active Practices (License Details)</h2>
                  <MedicalPractices />
                </div>
                }
              </div>
            </Content>
          </Layout>
        </>
      }

      {
        isLoading && <div className="auth-container">
          <div className="auth-preloader">
            <svg
              viewBox="0 0 102 102"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="big-circle"
                d="M101 51C101 78.6142 78.6142 101 51 101C23.3858 101 1 78.6142 1 51"
                stroke="#252525"
                stroke-width="2"
              />
              <path
                className="small-circle"
                d="M91 51C91 28.9086 73.0914 11 51 11C28.9086 11 11 28.9086 11 51"
                stroke="#252525"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
      }
    </Layout>
  );
}

export default MainLayout;
