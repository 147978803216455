import "./App.css";
import React from "react";
import Layout from "./layout/main/MainLayout";
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import { NoInternetConnection, Auth } from "@pages";
import ScrollToTop from "./widgets/scrollToTop";
import { ToastContainer } from "react-toastify";
import Auth from "./pages/Auth/Auth";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Layout />}></Route>
        <Route path="/authorization-code/callback">
          <Route index element={<Auth />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
